<template>
    <div class="mb-6 text-xl">
      <div v-if="validEmail" class="text-red-700 italic">
        enter your email above
      </div>
      <div v-else>
        <div v-if="!sentInvite">
          <form @submit.prevent="onSubmit">
            <button type="submit" class="px-2 pb-2 pt-2 inline-block rounded-lg 
            bg-primary-two hover:bg-blue-600 
            text-sm text-white cursor-pointer leading-none">
                Get the Invite
            </button>
          </form>
        </div>
        <div v-else class="pb-4" >
          <Sending2 />
          <div class="fadeIn leading-none">
            <div style="margin-top: -150px; z-index: -1;">
                <div style="font-size: 18px; padding: 40px 0px 5px 0px;">
                a calendar invitation has been sent to:
                </div>
                <div style="font-size: 26px; font-weight: 700;">
                {{ inviteeEmail }}
                </div>
                <div style="font-size: 14px; padding: 10px 0px 20px 0px">
                don't see the invite? check your spam/junk folder
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { EVENTAPI } from '@/assets/js/api.js'
import Sending2 from '@/components/loading/Sending2.vue'

export default {
    name: 'SendButton',

    props: {
        eventid: String,
        validEmail: Boolean,
        inviteeEmail: String
    },

    components: {
      Sending2
    },

    data () {
    return {
      sentInvite: false,
      eventThankyou: false,
      // snackUri: SNACKAPI,
      // email: this.inviteeEmail,
      // name: '',
      origin: 'landingpage',
      landing: ''
    }
  },

  computed: {
    inviteeUri: function () {
      return (
        EVENTAPI +
        this.eventid +
        '/invite?email=' +
        this.inviteeEmail +
        '&name=%20&origin=' +
        this.origin +
        '&landing=' +
        this.landing
      )
    }
  },

  methods: {

    onSubmit () {
      const formData = {
        name: this.name,
        email: this.email
      }
      axios
        .get(this.inviteeUri)
        .then(response => {
          this.sentInvite = true
        })
        .catch(error => {
          this.sentInvite = true
        })
    },
  }

}
</script>

<style scoped>
@-webkit-keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.fadeIn {
    animation: fadeIn 5.5s both;
    -webkit-animation: fadeIn 5.5s both;
}
</style>