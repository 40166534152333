<template>
    <div>
        <div v-if="!oneEvent.uid">
          <h1 class="text-3xl font-bold">404 Events Not Found</h1>
        </div>
        <div v-else>
          <div v-if="!logoImg"></div>
          <div v-else class="mx-auto mt-5 text-center" style="max-width: 350px;">
            <img :src="logoImg" style="margin: 0 auto; max-height: 100px;">
          </div>
          <OrgEvents class="max-w-screen-md mx-auto" v-bind:orgEmail="oneEvent.mailto" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import OrgEvents from '@/components/upcoming/Upcoming2.vue'

export default {
  name: 'UpcomingEvents2',
  props: ['eventid'],

  components: {
    OrgEvents
  },

  data () {
    return {
      logoImg: this.$route.query.img1
    }
  },

  created () {
    this.LOAD_EVENT(this.eventid)
  },

  mounted () {
    window.scrollTo(0, 0)
  },

  computed: {
    ...mapState([
      'oneEvent'
    ])
  },

  methods: {
    ...mapActions([
      'LOAD_EVENT'
    ])
  }

}
</script>

<style scoped>
h1 {
  margin: 40px;
}
</style>
