<template>
  <div class="text-left px-4">
    <div class="">
      <div class="text-primary-two pt-8 text-center " >
        <!-- <div class="italic text-5xl font-medium leading-relaxed">stop f*cking around</div> -->
        <div class="italic leading-none text-2xl">Get the Calendar Invite directly to your INBOX!</div>
        <div class="italic text-base">enter your email, pick your events, get the invite, & RSVP</div>
        
      </div>
      <!-- <div>
        <EmailInput class="mx-auto my-4" />
      </div> -->
      <div class="text-center m-4 pb-4">
        <label for="email"></label>
        <input 
            type="email" 
            v-model="invitee.email" 
            id="email" 
            name="email"
            autofocus
            class="px-2 py-1 rounded-lg" />
        <div class="text-primary-two">
          <div v-if="$v.invitee.email.$invalid" class="font-medium italic">
              <span v-if="!$v.invitee.email.required">Email is required</span>
              <span v-if="!$v.invitee.email.email">Email is invalid</span>
          </div>
          <div v-else class="text-primary-two" >
              <i class="far fa-check"></i>
              valid email
          </div>
        </div>
      </div>
    </div>
    <div class="text-3xl font-meidum mt-2 pb-2">Our Upcoming events</div>
    <div v-if="evFilter === 'futureEvents' && futureEvents.length >= 1">
      <div v-for="event in futureEvents" :key="event.uid" class="w-full">
        <div>
          <!-- BEGIN Event Review Card -->
          <div v-if="event.status === 'confirmed'" class="flex flex-row mt-8 px-2  text-center">
            <div class="z-0" >
              <DateCard :start="event.dtstart" />
            </div>
            <div class="w-full -ml-12 mr-0 mt-6 mb-4 pl-12 bg-white shadow-lg rounded-lg">
              <EventCard v-bind="event" />
              <SendButton 
                :eventid="event.uid" 
                :validEmail="$v.invitee.email.$invalid"
                :inviteeEmail="invitee.email" />
            </div>
          </div>
          <!-- END Event Review Card -->
        </div>
      </div>
    </div>
    <div v-else class="mt-8 text-1xl font-bold text-red-700">
        No Upcoming Events
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { required, email } from "vuelidate/lib/validators"
// import { useVuelidate } from '@vuelidate/core'
import DateCard from '@/components/ui/DateCard.vue'
import EventCard from '@/components/ui/EventNoMessage.vue'
import SendButton from '@/components/ui/SendInvite.vue'
// import EmailInput from '@/components/ui/EmailInput.vue'

export default {
  name: 'OrgEvents',
  props: ['orgEmail'],

  components: {
    DateCard,
    EventCard,
    SendButton,
    // EmailInput
  },

  data () {
    return {
      invitee: {
        email: ''
      },
      validEmail: false,
      // inviteeEmail: v$.invitee.$model.email,
      // inviteeEmail: this.invitee.email,
      evFilter: 'futureEvents',
      createdSort: 'dtstart',
      createdDir: 'asc',
      futureSort: 'dtstart',
      futureDir: 'asc',
      pastSort: 'dtstart',
      pastDir: 'desc',
      eventuid: ''
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  validations () {
    return {
      invitee: {
        // name: { required },
        email: { required, email }
      }
    }
  },

  /* validations: {
      invitee: {
          email: { required, email },
      }
  }, */

  created () {
    this.LOAD_ORGEVENTS(this.orgEmail)
  },

  mounted () {
    window.scrollTo(0, 0)
  },

  computed: {
    ...mapState([
      'orgEvents'
    ]),

    rightNow () {
        return this.$moment().format('x')
    },

    lastSeven () {
      return this.$moment().subtract(1, 'weeks').format('x')
    },

    futureEvents () {
      return this.$store.state.orgEvents.filter(
        orgEvents => this.$moment(orgEvents.dtstart).format('x') >= this.rightNow
      )
      .sort((a, b) => {
            let modifier = 1
            if(this.futureDir === 'desc') modifier = -1
            if(a[this.futureSort] < b[this.futureSort]) return -1 * modifier
            if(a[this.futureSort] > b[this.futureSort]) return 1 * modifier
            return 0
        })
    },
    
    pastEvents () {
      return this.$store.state.orgEvents
        .filter(
          orgEvents => this.$moment(orgEvents.dtstart).format('x') < this.rightNow
        )
        .sort((a, b) => {
            let modifier = 1
            if(this.pastDir === 'desc') modifier = -1
            if(a[this.pastSort] < b[this.pastSort]) return -1 * modifier
            if(a[this.pastSort] > b[this.pastSort]) return 1 * modifier
            return 0
        })
    },

    createdEvents () {
      return this.$store.state.orgEvents
        .filter(
          orgEvents => this.$moment(orgEvents.created).format('x') > this.lastSeven
        )
        .sort((a, b) => {
            let modifier = 1
            if(this.createdDir === 'desc') modifier = -1
            if(a[this.createdSort] < b[this.createdSort]) return -1 * modifier
            if(a[this.createdSort] > b[this.createdSort]) return 1 * modifier
            return 0
        })
    }
  },

  methods: {
    ...mapActions([
      'LOAD_ORGEVENTS',
      'LOAD_ORGEVENTS_STATS'
    ]),
  }

}
</script>


<style scoped>

</style>
