<template>
    <div>
        <!-- date card -->
        <div class="flex-none w-20 max-h-16 leading-tight text-center">
            <div class="p-1 bg-primary-two text-sm text-white rounded-t-lg border-primary-two border-2">
                {{ start | moment("MMM YYYY") }}
            </div>
            <!-- <br/> -->
            <div class="p-0 bg-white rounded-b-lg border-primary-two border-2 text-primary-two">
                <div class="mb-0 text-3xl font-medium">
                    {{ start | moment("D") }}
                </div>
                <div class="-mt-1 mb-2 text-xs capitalize">
                    {{ start | moment("dddd") }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DateCard',
    props: {
        start: Number
    }
}
</script>