<template>
    <div>
        <!-- detail card -->
        <div class="bg-white text-left">
            <!-- detail card -->
            <div class="flex-grow p-4 text-left">
                <div class="font-medium text-2xl leading-none">
                    {{ this.$attrs.summary_html }}
                </div>
                <div class="text-light mt-2">
                    <i class="far fa-clock"></i> start time 
                    {{ this.$attrs.dtstart | moment("h:mm a") }}
                </div>
                <div class="text-light mt-0">
                    <i class="fas fa-history fa-flip-horizontal"></i> duration 
                    {{ (this.$attrs.dtstart - this.$attrs.dtend) * -1000 | duration('humanize') }}
                </div>
                <div class="text-light mt-0 pl-1 break-words">
                    <span class="font-medium"><i class="far fa-map-marker-alt"></i></span>
                    <span class="pl-2" v-if="!this.$attrs.location_html">No Location Provide</span>
                    <span class="pl-2" v-else>{{ this.$attrs.location_html }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EventCard',
}
</script>
